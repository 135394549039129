<template>
  <stzh-footer variant="egov">
    <stzh-link
      slot="link"
      href="https://www.stadt-zuerich.ch/de/service/rechtliche-hinweise.html"
      rel="noopener noreferrer"
      target="_blank">
      Rechtliche Hinweise
    </stzh-link>
    <stzh-link
      slot="link"
      href="https://www.stadt-zuerich.ch/de/service/impressum.html"
      rel="noopener noreferrer"
      target="_blank">
      Impressum
    </stzh-link>
    <stzh-link
      slot="link"
      href="https://www.stadt-zuerich.ch/de/service/barrierefreiheit.html"
      rel="noopener noreferrer"
      target="_blank">
      Barrierefreiheit
    </stzh-link>
  </stzh-footer>
</template>
<script>
export default {
  name: 'StzhUnprotectedViewFooter',
};
</script>
