<template>
  <stzh-header hide-metabar sticky="always-full" class="stzh-header">
    <img slot="logo" :src="lszLogo" alt="Logo City Zürich, go to homepage">
  </stzh-header>
</template>
<script>
import lszLogo from '@/assets/images/logo/lsz-logo.svg';

export default {
  name: 'StzhUnprotectedViewHeader',
  data() {
    return {
      lszLogo,
    };
  },
};
</script>

<style lang="scss" scoped>
  .stzh-header {
    position: relative;
    z-index: 601 !important; //stzh-dropdown popover is 600
  }
</style>
